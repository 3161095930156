<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
 import { required , requiredIf , email ,maxLength} from "vuelidate/lib/validators";
// import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
// import Infrastructure from './infrastructure.vue';
import Swal from "sweetalert2";
import { emailFormatter} from "@/helpers/functions";

import Multiselect from 'vue-multiselect';
import {
  fetchLifebasesApi,
  fetchSuppliersListApi,
  fetchUserStructuresTypesApi,
  /* fetchDirectionsListApi,
  fetchDivisionsListApi,
  fetchDepartmentsListApi,
  fetchServicesListApi, */
  fetchRolesListApi,
} from "@/api/common";
export default {

    page: {
        title: "Nouvel Utilisateur",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {Multiselect },
    import: { fetchSuppliersListApi, 
            fetchUserStructuresTypesApi,
            /* fetchDirectionsListApi,
            fetchDivisionsListApi,
            fetchDepartmentsListApi,
            fetchServicesListApi,  */
            fetchRolesListApi,
            },
    props:{
     selectedUser: null
    },
  data() {
    return {
      suppliers: [],
      users: [],
      employeesList: [],
      bases: [],
      directions: [],
      divisions: [],
      departments: [],
      services: [],
      strList : null,
      strType : [
          {text:'Direction',value:1},
          {text:'Division',value:2},
          {text:'Services',value:3},
          {text:'Service',value:4},
          {text:'Section',value:5},
      ],
      structures : [],
      roles: [],
      userForm: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        supplier: "",
        type: "",
        employees : [],
        directSuperior: "",
        role: [],
        direction: "",
        division: "",
        department: "",
        service: "",
        password: "",
        id_number: "",
        selectedBases:[],
        superior_id  : '',
      },
      types: [
        {
          id: "employee",
          name: "Employé SH",
        },

        {
          id: "supplier_staff",
          name: "Employé Prestataire",
        },
        {
          id: "other",
          name: "Autres",
        },
      ],
      userStructuresTypes: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      disabled : true,
      canDelte : true,
      disabledAdd : false,
      disabledUpdate : true,
      lastSelected : null,
    };
  },
  validations: {
    userForm: {
      employees: { required: false
              },
      email: { required: requiredIf(function() {
                    return this.userForm.type.id != 'other'
                }),   
          email: (val) => email(emailFormatter(val)), 
          },
      // structureType : { required : false },
      username: { required },
      password:{
        required: false
      },
      role:{ required },
      selectedBases:{
        required: requiredIf(function(){
          return this.disabledAdd == true;
        })
      },
      id_number: { required: requiredIf(function() {
                    return this.userForm.type.id != 'other'
                }),   },
      type: { required },
      superior_id :{ required: false },
    },
  },
 created() {
    this.fetchLifebasesList();
    this.fetchSuppliersList();
    this.fetchUserStructuresTypes();
    // this.fetchDirectionsList();
    // this.fetchDivisionsList();
    // this.fetchDepartmentsList();
    // this.fetchServicesList();
    this.fetchWarehouseList();
    this.fetchRolesList();
  },
  methods: {
    getStr(){
         var urlStr
        switch (this.strList) {
            case 1:
                urlStr = "/organisation/hierarchy/direction/getDirectionsList";
                break;
            case 2:
                urlStr = "/organisation/hierarchy/division/getDivisionsList";
                break;
            case 3:
                urlStr = "/organisation/hierarchy/departement/getDepartementsList";
                break;
            case 4:
                urlStr = "/organisation/hierarchy/service/getServicesList";
                break;
            case 5:
                urlStr = "/organisation/hierarchy/section/getSectionsList";
                break;
        }
          let loader = this.$loading.show({
                color: '#000000',
                loader: 'spinner',
                width: 64,
                height: 64,
                backgroundColor: '#ffffff',
                opacity: 0.5,
                zIndex: 999,
            })
            this.$http.get(urlStr)
            .then((res) => {
                this.structures = res.data;
                
                loader.hide();
            }).catch((error) => {
                loader.hide();
            this.$toast.error(error.message);
            })
            .finally(() => {});
    },
    addBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = false;
      this.disabledUpdate = true;
      this.canDelte       = true;
      this.clearForm();
    },
    duplicateBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = false;
      this.userForm.uuid           ="";
      this.userForm.firstName      ="";
      this.userForm.lastName       ="";
      this.userForm.email          ="";
      this.userForm.username       ="";
      this.userForm.password       ="";
      this.userForm.id_number      ="";
      this.userForm.employees     = "";
    },
    editBtn(){
      this.disabledAdd    = false;
      this.disabledUpdate = true;
      this.disabled       = false;
    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledEdit   = true;
      this.disabledUpdate = false;
      this.disabledCancel = true;
      this.clearForm();
      if(this.lastSelected != null)
      this.setselectedUser();
    },
    fetchWarehouseList() {
      this.$http
        .post("/system/stock/warehouses/list")
        .then((res) => (this.warehouses = res.data.original.data))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    getEmployees(){
      this.userForm.superior_id = [];
      if(this.userForm.type.id == "employee") this.fetchEmployeesList() 
    },

    getEmployeesPrestataire(){
      this.userForm.superior_id = [];
      if(this.userForm.type.id == "supplier_staff" && !this.userForm.supplier == "")
      this.fetchEmployeesList() 
    },

    setEmployees(){
      this.userForm.firstName = this.userForm.employees.first_name;
      this.userForm.lastName = this.userForm.employees.last_name;
      this.userForm.id_number = this.userForm.employees.employee_number;
    },

    fetchLifebasesList(){
      fetchLifebasesApi()
        .then((res) => (this.bases = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    /* fetchDirectionsList(){
      fetchDirectionsListApi()
        .then((res) => (this.directions = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchDivisionsList(){
      fetchDivisionsListApi()
        .then((res) => (this.divisions = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchDepartmentsList(){
      fetchDepartmentsListApi()
        .then((res) => (this.departments = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },*/
    fetchEmployeesList(){
      this.$http
        .post("/system/users/employeesList",this.userForm)
        .then((res) => {
          this.employeesList = [];
          this.employeesList = res.data.original.data })
        .catch(() => {})
        .finally(() => {});
    }, 
    fetchUserStructuresTypes() {
      fetchUserStructuresTypesApi()
        .then((res) => (this.userStructuresTypes = res.data.original.data))
        .catch(() => {})
        .finally(() => {});
    },
    fetchSuppliersList() {
      fetchSuppliersListApi()
        .then((res) => (this.suppliers = res.data.suppliers))
        .catch(() => {})
        .finally(() => {});
    },
    fetchRolesList(){
      fetchRolesListApi()
      .then((res) => (this.roles = res.data.original.data))
        .catch(() => {})
        .finally(() => {});
    },
    deleteUser() {
      var typeName = this.userForm.firstName+" "+this.userForm.lastName;
      var typeUid  = this.userForm.uuid;
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le type :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/system/users/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  this.$emit('reloadTable',true)
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    formSubmit() {
      this.submitted = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitted = false
        this.$http.post("/system/users/store", this.userForm)
        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.$emit('reloadTable',true)
              if(this.userForm.uuid == "") {
                this.clearForm();
              }
              this.cancelBtn();
              break;

            case 500:
              this.$toast.error(res.data.original.msg);
              break;
           /*  case 422:
              console.log(res.data.original.errors);
              // this.allerros = error.response.data.errors;
              this.$toast.error(res.data.original.msg);
              break; */
          }
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          if(error.response.data.errors.email) {this.$toast.error("l'email est obligatoire et doit être unique");} 
          else if(error.response.data.errors.username) {this.$toast.error("le Nom d'utilisateur est obligatoire et doit être unique");}
          else { this.$toast.error(error.message);}
        })
        .finally(() => {});
      }
    },
    clearForm(){
        this.userForm.uuid           ="";
        this.userForm.firstName      ="";
        this.userForm.lastName       ="";
        this.userForm.email          ="";
        this.userForm.username       ="";
        this.userForm.password       ="";
        this.userForm.supplier       ="";
        this.userForm.type           ="";
        this.userForm.directSuperior ="";
        this.userForm.role           =[];
        // this.userForm.structureType  ="";
        this.userForm.direction      ="";
        this.userForm.division       ="";
        this.userForm.department     ="";
        this.userForm.service        ="";
        this.userForm.selectedBases  =[];
        this.userForm.id_number      ="";
        this.userForm.supplier      = "";
        this.userForm.employees     = "";
        this.userForm.superior_id   = "" ;  
    },
    setselectedUser(){
        this.userForm.uuid           = this.lastSelected.uuid;
        this.userForm.firstName      = this.lastSelected.first_name;
        this.userForm.lastName       = this.lastSelected.last_name;
        this.userForm.email          = this.lastSelected.email;
        this.userForm.username       = this.lastSelected.username;
        this.userForm.supplier       = this.lastSelected.supplier;
        this.userForm.type           = this.lastSelected.account_typeObj;
        this.userForm.directSuperior = this.lastSelected.directSuperior;
        this.userForm.role           = this.lastSelected.role;
        this.userStructuresTypes     = this.lastSelected.structType;
        this.userForm.selectedBases = this.lastSelected.lifebase;
        this.userForm.id_number     = this.lastSelected.id_number;
        if(this.userForm.type.id == "employee") this.fetchEmployeesList() 
        else this.fetchEmployeesList()
        this.userForm.supplier      = this.lastSelected.supplier;
        this.userForm.employees     = this.lastSelected.employee;
        this.userForm.superior_id   = this.lastSelected.superior_id ;  

    }
  },

  watch:{
    selectedUser: function(selectedUser){
      this.clearForm();
      this.disabledUpdate = true;
      this.canDelte       = true;
      this.disabledAdd    = false;
      if(selectedUser.length> 0 &&  undefined != selectedUser[0]){
        this.disabledUpdate = false;
        this.canDelte       = false;
        this.userForm.uuid           = selectedUser[0].uuid;
        var loader = this.$loading.show();
        this.$http
        .post("/system/users/getSingleUserData/"+ selectedUser[0].uuid)
        .then((res) => {
          loader.hide();
          var user = res.data.data;
          // this.userForm = user;
          this.userForm.firstName      = user.first_name;
          this.userForm.lastName       = user.last_name;
          this.userForm.email          = user.email;
          this.userForm.username       = user.username;
          this.userForm.supplier       = user.supplier;
          this.userForm.type           = user.account_typeObj;
          this.userForm.directSuperior = user.directSuperior;
          this.userForm.role           = user.role;
          this.userStructuresTypes     = user.structType;
          this.userForm.structureType  = user.structType;
          this.userForm.selectedBases = user.lifebase;
          this.userForm.id_number     = user.id_number;
          if(this.userForm.type.id == "employee") this.fetchEmployeesList() 
          else this.fetchEmployeesList()
          this.userForm.supplier      = user.supplier;
          this.userForm.employees     = user.employee;
          this.userForm.superior_id   = user.superior_id ;  

          this.lastSelected           = selectedUser[0]
        })
        .catch(() => {
          loader.hide();
        })
        .finally(() => {
          loader.hide();
        });
        
        /* if(selectedUser[0].structure_id){
          var sil = (selectedUser[0].structure_id).length;
           switch (sil) {
               case 3:
                   this.strList = 1;
                   break;
               case 6:
                   this.strList = 2;
                   break;
               case 9:
                   this.strList = 3;
                   break;
               case 12:
                   this.strList = 4;
                   break;
               case 15:
                   this.strList = 5;
                   break;
           
               default:
                   break;
           }
           this.getStr();
           this.userForm.Emp_structure_id     = selectedUser[0].structure_id;
        } */
        
        
      }
    }  
  },
  mounted(){

},


}
</script>
<style >
@media screen and (-webkit-min-device-pixel-ratio:0) {

    /*Chrome CSS here*/
  #StickyUF {
    position: fixed;
    right: 0;
    top: 50%;
    width: 4em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
    
}

@supports (-moz-appearance:none){ 
  #StickyUF {
    position: fixed;
    right: 0;
    top: 50%;
    width: 1em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
}
  .finish-button{
    background-color:#43A047 !important;
    border-color: #43A047 !important;
  }
  
  .rounded-pillUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #8FC412 ;
  }
  .btn-editUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteUF #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-printUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
</style>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title  pb-2">
              Details
            </h4>
            <ul id="StickyUF">
                <li>
                  <button type="button" @click="addBtn" :disabled="disabledAdd" class="btn btn-addUF rounded-pillUF float-right mr-1 mt-1">
                  <i class="fas fa-plus text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button" :disabled="disabledAdd" @click="duplicateBtn" class="btn btn-duplicateUF rounded-pillUF float-right mr-1 mt-1">
                  <i class="far fa-copy text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button" :disabled="disabledUpdate" @click="editBtn" class="btn btn-editUF rounded-pillUF float-right mr-1 mt-1">
                  <i class="fas fa-pencil-alt text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button" :disabled="canDelte" @click="deleteUser" class="btn btn-deleteUF rounded-pillUF float-right mr-1 mt-1">
                   <i class="far fa-trash-alt text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button"  class="d-none btn btn-uploadUF rounded-pillUF float-right mr-1 mt-1">
                  <i class="fas fa-file-upload  text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button"  class="d-none btn btn-downloadUF rounded-pillUF float-right mr-1 mt-1">
                  <i class="fas fa-file-download text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button"  class="d-none btn btn-printUF rounded-pillUF float-right mr-1 mt-1">
                  <i class="fas fa-print"></i>
                  </button>
                </li>
            </ul>
            <fieldset :disabled="disabled">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Type d'utilisateur *</label
                    >
                    <multiselect
                      @close="getEmployees()"
                      v-model="userForm.type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :select-label="''"
                      :options="types"
                      :allow-empty="false"
                      :class="{'disabledMS': disabled}"
                      :deselect-label="''"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div
                      v-if="submitted && $v.userForm.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.email.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3" v-if="userForm.type.id == 'supplier_staff'">
                  <div class="form-group">
                    <label for="formrow-email-input">Prestataire *</label>
                    <multiselect
                      @close="getEmployeesPrestataire()"
                      v-model="userForm.supplier"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="suppliers" 
                      :select-label="''"
                      :deselect-label="''"
                      placeholder="Select Supplier"
                      :allow-empty="false"
                      :class="{'disabledMS': disabled}"
                    >
                      <template
                        slot="singleSupplier"
                        slot-scope="{ supplier }"
                        >{{ supplier.name }}</template
                      >
                    </multiselect>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3 "  v-if="userForm.type.id != 'other'">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Employees *</label
                    >
                    <multiselect
                      @close="setEmployees()"
                      v-model="userForm.employees"
                      :searchable="true"
                      track-by="id"
                      label="fullName" 
                      :select-label="''"
                      :deselect-label="''"
                       :multiple="false"
                      :options="employeesList"
                      :allow-empty="false"
                      :class="{'disabledMS': disabled}"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{option.fullName }}
                      </template>
                    </multiselect>
                    <div
                      v-if="submitted && $v.userForm.employees.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.employees.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                
                <div class="col-xs-6 col-md-6 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Superviseur *</label>
                    <multiselect
                      v-model="userForm.superior_id"
                      :searchable="true"
                      track-by="id"
                      label="fullName" 
                      :select-label="''"
                      :deselect-label="''"
                      :multiple="false"
                      :options="employeesList"
                      :allow-empty="false"
                      :class="{'disabledMS': disabled}"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{option.fullName }}
                      </template>
                    </multiselect>
                    
                    <div v-if="submitted && $v.userForm.superior_id .$error"
                      class="invalid-feedback">
                      <span v-if="!$v.userForm.superior_id .required">
                        Le champ est obligatoire.</span>

                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Nom *</label>
                    <input
                      id="projectname"
                      v-model="userForm.firstName"
                      type="text"
                      class="form-control"
                      placeholder=""
                      :disabled="true"
                    />
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Prénom *</label>
                    <input
                      id="projectname"
                      v-model="userForm.lastName"
                      type="text"
                      class="form-control"
                      :class="{
                        'isDisabled disabledBG' : userForm.type.id != 'other',
                      }"
                      placeholder=""
                      :disabled="true"
                    />
                  </div>
                </div>
            
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">N° Employé *</label>
                    <input
                      id="projectname"
                      v-model="userForm.id_number"
                      type="text"
                      class="form-control "
                      :class="{'isDisabled disabledBG' : userForm.type.id != 'other'}"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Email *</label>
                    <input
                      id="projectname"
                      v-model="userForm.email"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.userForm.email.$error,
                        'disabledMS': disabled
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.userForm.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.email.$error"
                        >le champ est obligatoire et doit être unique.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Nom d'utilisateur *</label>
                    <input
                      id="projectname"
                      v-model="userForm.username"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.userForm.username.$error,
                        'disabledMS': disabled
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.userForm.username.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.username.$error"
                        >le champ est obligatoire et doit être unique.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Mot de passe *</label>
                    <input
                      id="projectname"
                      v-model="userForm.password"
                      type="password"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.userForm.password.$error,
                        'disabledMS': disabled
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.userForm.password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.password.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Rôle *</label>
                    <multiselect
                      v-model="userForm.role"
                      :searchable="true"
                      track-by="id"
                      label="display_name"
                      :options="roles"
                      placeholder="Select user Role"
                      :allow-empty="false"
                      :select-label="''"
                      :deselect-label="''"
                      :class="{'disabledMS': disabled}"
                    >
                      <template slot="singleRole" slot-scope="{ role }">{{
                        role.display_name
                      }}</template>
                    </multiselect>
                    <div
                      v-if="submitted && $v.userForm.role.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.role.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md col-lg">
                  <div class="form-group">
                    <label for="formrow-email-input">Base de vie *</label>
                    <Multiselect
                      v-model="userForm.selectedBases"
                      :options="bases"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                       :select-label="''"
                      :deselect-label="''"
                      placeholder="Selectionner une ou plusieurs bases"
                      label="name"
                      track-by="id"
                      :preselect-first="true"
                      :class="{'disabledMS': disabled}"
                    />
                    <div
                      v-if="submitted && $v.userForm.selectedBases.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.selectedBases.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              <!--   <div class="col-sm-12 col-md-4 col-lg-3 ">
                    <label for="formrow-inputCity">Type de Structure *</label>
    
                    <select class="form-control" @change="getStr()" v-model="strList" :class="{'disabledMS': disabled}" >
                        <option v-for="strT in strType" :key="strT.value" :value="strT.value"> {{strT.text}} </option>
                    </select>
                    <div
                        v-if="submitted && $v.userForm.structureType.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.userForm.structureType.required"
                        >La structure est obligatoire.</span
                        >
                    </div>
                </div>
                <div class="col-sm-12 col-lg-3 col-md-4">
                    <label for="formrow-inputCity">Structure *</label>
    
                    <select class="form-control" v-model="userForm.structureType" 
                    @blur="$v.userForm.structureType.$touch"
                    :class="{
                    'is-invalid': submitted && $v.userForm.structureType.$error,
                    'disabledMS': disabled
                    }">
                        <option v-for="structure in structures" :key="structure.code" :value="structure.code"> {{structure.name}} </option>
                    </select>
                    <div
                        v-if="submitted && $v.userForm.structureType.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.userForm.structureType.required"
                        >La structure est obligatoire.</span
                        >
                    </div>
                </div> -->
                <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Supérieur hiérarchique *</label
                    >
                    <multiselect
                      v-model="userForm.type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="types"
                      placeholder="Select user type"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div
                      v-if="submitted && $v.userForm.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.email.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div> -->

              </div>
              <div class="col-md-12" v-show="!disabled">
                <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                  <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                </button>
                <button type="submit" class="btn btn-label btn-primary float-right" >
                  <i class="far fa-save label-icon "></i> Enregistrer
                </button>
              </div>
            </form>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
