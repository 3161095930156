<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { fetchUsersListApi } from "@/api/common";
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";

import Userform from "./components/form";
import Swal from "sweetalert2";
export default {
  import: { fetchUsersListApi },
  page: {
    title: "Utilisateurs",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Userform,
    Dataset,
    DatasetItem,
    DatasetShow,
  },
  data() {
    return {
      selectedUser: null,
      tableData: [],
      List_User: [],
      List_User_: {},
      rows: "",
      units: {},
      search: '',
      defaultShow: 5,
      active: true,
      selectedRows: [],
      user: undefined,
      title: "Utilisateurs",
      items: [
        {
          text: "Paramètres",
          to: { name: "base.settings.index" },
        },
        {
          text: "Utilisateurs",
          active: true,
        },
      ],
      totalPages: 0,
      currentPage: 1,
      pageSize: 5,
      searchDisable : false,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      cols: [
        {
          name: "Désignation",
          field: "fullName",
          sort: "",
        },
        {
          name: "Nom d'utilisateur",
          field: "status",
          sort: "",
        },
        {
          name: "Email",
          field: "email",
          sort: "",
        },
        {
          name: "Base de Vie",
          field: "lifebases",
          sort: "",
        },
      ],
      selectedIndex: -1,
      reloadTable: false,
    };
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;
    // fetch divisions from api
    this.fetchUserList();
  },
  watch: {
    selectedRows() {},
    reloadTable(val) {
      if (val) {
        this.fetchUserList();
      }
    },
    user: {
      handler(val) {
        this.List_User.push({
          name: val.name,
          description: val.description,
        });
        // console.log(this.List_User);
      },
    },
  },
  methods: {
    selectRow(row, index) {
      if (this.selectedIndex == index) {
        this.selectedRows = [];
        this.selectedIndex = -1;
      } else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },

    onShowChange(event){
       this.fetchUserList();
     /*  var loader = this.$loading.show();
      var show = this.defaultShow;
      if(!event){
        show = this.defaultShow;
      }else{
        show = event;
      }
      this.$http
        .post("/system/users/paginate?show=" + show)
        .then((res) => {
          this.List_User = res.data;
          this.selectedRows = [];
          this.selectedIndex = -1;
          loader.hide();
          this.reloadTable = false;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        }); */
    },
    clearSearch (){
      this.search = "";
      this.fetchUserList();
    },
    searchUnit (){
      this.fetchUserList();
    },
    fetchUserList(page = 1) {
      let loader = this.$loading.show();
      var show   = this.defaultShow;
      this.searchDisable = true;
      this.$http
        .post("/system/users/paginate?page=" + page+"&show=" + show +"&q="+this.search)
        .then((res) => {
          this.List_User = res.data;
          this.selectedRows = [];
          this.selectedIndex = -1;
          loader.hide();
          this.reloadTable = false;
          this.searchDisable = false;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    getUser() {
      this.$http
        .get("/system/users/list")
        .then((response) => {
          console.log(response.data);
          this.data = response.data;
          this.selectedRows.push(response.data[0]);
        })
        .catch((error) => console.log(error));
    },
    refreshUser() {
      this.getUser();
    },
    deleteUser(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/system/users/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  _this.fetchUserList();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    editUser(typeUid) {
      let loader = this.$loading.show();
      this.$http
        .post("/system/users/getSingleUserData/" + typeUid)
        .then((res) => {
          var status = res.data.original.status;
          var user = res.data.original.data.user;

          loader.hide();
          switch (status) {
            case 200:
              this.currentSelectedUser = user;
              this.updateTabsUi();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    handleLoadUserDataToFormComponent(selectedUser) {
      if (selectedUser) {
        this.editUoms(selectedUser.uuid);
      }
    },

    updateTabsUi() {
      this.generalInfosSetUp = Boolean(
        this.currentSelectedUser.general_infos_setup
      );
      this.scopeSetUp = Boolean(this.currentSelectedUser.scope_setup);
      this.financeInfosSetUp = Boolean(
        this.currentSelectedUser.financial_infos_setup
      );
      this.priceListInfosSetUp = Boolean(
        this.currentSelectedUser.pricelist_infos_setup
      );
      this.synopticsInfosSetUp = Boolean(
        this.currentSelectedUser.synptics_infos_setup
      );
    },

    handleGeneralInfoStepSavedEvent(user) {
      this.currentSelectedUser = user;

      this.generalInfosSetUp = true;
      // refresh contracts list
      this.shouldRefreshUserTable = true;
      // jump to next tab
      this.formStep = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                @click="active = !active"
                style="background:#e5e9f0; color:black !important; border:none !important;"
              >
                <div class="row">
                  <div class="col text-left mt-1">
                    <i class="mdi mdi-format-list-bulleted"></i> Liste des
                    utilisateurs
                  </div>
                  <div class="col text-right">
                    <i
                      v-if="active"
                      class="mdi mdi-chevron-up font-size-18"
                    ></i>
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i>
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-1"
              :visible="active"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="shadow-lg">
                <div class="row">
                  <div class="col-9">
                      <input type="text"  :disabled="searchDisable" placeholder="Search" v-model="search" class="form-control form-control">
                    </div>
                  <div class="col-3">
                    <b-button variant="primary  btn-label" @click="searchUnit"> <i class="fas fa-search label-icon" ></i> Rechercher </b-button>
                    <b-button variant="warning ml-4  btn-label" @click="clearSearch"> <i class="fas fa-times-circle label-icon" ></i> Réinitialiser </b-button>
                  </div>
                </div>
                <dataset v-slot="{ ds }" :ds-data="List_User.data">
                  <div class="row">
                    <div class="col-md-6 mb-2 mb-md-0"></div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table
                          class="table table-hover d-md-table table-striped"
                        >
                          <thead>
                            <tr>
                              <th v-for="th in cols" :key="th.field">
                                {{ th.name }}
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr
                                @click="selectRow(row, rowIndex)"
                                :class="{
                                  'bg-soft-info': selectedIndex == rowIndex,
                                }"
                                style="cursor:pointer"
                              >
                                <td>{{ row.full_name }}</td>
                                <td>{{ row.username }}</td>
                                <td>{{ row.email }}</td>
                                <td>{{ row.lifebases }}</td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex flex-md-row flex-column justify-content-between align-items-center"
                  >
                    <!-- <dataset-info class="mb-2 mb-md-0" /> -->
                    <dataset-show class="mb-2" v-model="defaultShow" @changed="onShowChange" :ds-show-entries="defaultShow"/>
                    <pagination
                        :data="Object.assign({},List_User)"
                        :limit="defaultShow"
                        class="float-right"
                        @pagination-change-page="fetchUserList"
                      />
                  </div>
                </dataset>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <Userform
          :selectedUser="selectedRows"
          @reloadTable="reloadTable = $event"
        />
      </div>
    </div>
  </Layout>
</template>
